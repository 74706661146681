/* You can add global styles to this file, and also import other style files */


/* Provide sufficient contrast against white background */

* {
    margin: 0px;
    cursor: default;
}

body {
    min-height: 100vh;
    background-color: #fff;
}

*.link,
a {
    cursor: pointer !important;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.line {
    height: 1px;
    background-color: white;
    width: 100%;
}

@font-face {
    font-family: "montserrat_alternatesregular";
    src: url("../../ClientApp/src/assets/fonts/montserratalternates-regular-webfont.woff2") format("woff2"), url("../../ClientApp/src/assets/fonts/montserratalternates-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "indie_flowerregular";
    src: url("../../ClientApp/src/assets/fonts/indieflower-regular-webfont.woff2") format("woff2"), url("../../ClientApp/src/assets/fonts/indieflower-regular-webfont.woff") format("woff"), url("../../ClientApp/src/assets/fonts/IndieFlower-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "quicksandlight";
    src: url("../../ClientApp/src/assets/fonts/quicksand-variablefont_wght-webfont.woff2") format("woff2"), url("../../ClientApp/src/assets/fonts/quicksand-variablefont_wght-webfont.woff") format("woff"), url("../../ClientApp/src/assets/fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "impact-tm";
    src: url("../../ClientApp/src/assets/fonts/impact.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alataregular';
    src: url('../../ClientApp/src/assets/fonts/alata-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/alata-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'architects_daughterregular';
    src: url('../../ClientApp/src/assets/fonts/architectsdaughter-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/architectsdaughter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arimoregular';
    src: url('../../ClientApp/src/assets/fonts/arimo-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/arimo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'caveatregular';
    src: url('../../ClientApp/src/assets/fonts/caveat-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/caveat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'courgetteregular';
    src: url('../../ClientApp/src/assets/fonts/courgette-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/courgette-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'farsanregular';
    src: url('../../ClientApp/src/assets/fonts/farsan-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/farsan-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'grupporegular';
    src: url('../../ClientApp/src/assets/fonts/gruppo-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/gruppo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kite_oneregular';
    src: url('../../ClientApp/src/assets/fonts/kiteone-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/kiteone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'playfair_displayregular';
    src: url('../../ClientApp/src/assets/fonts/playfairdisplay-variablefont_wght-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/playfairdisplay-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poiret_oneregular';
    src: url('../../ClientApp/src/assets/fonts/poiretone-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/poiretone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ralewaythin';
    src: url('../../ClientApp/src/assets/fonts/raleway-variablefont_wght-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/raleway-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('../../ClientApp/src/assets/fonts/roboto-regular-webfont.woff2') format('woff2'), url('../../ClientApp/src/assets/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
